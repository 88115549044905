import React from 'react';


interface State {

}

interface Props {
    headline: string;
  
    icon: string;
}

export default class Benefit extends React.Component<Props, State> {
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){

        return(
            <div className="benefit">
                <img className="benefit_icon" src={this.props.icon}></img>
                <p className="headline">{this.props.headline}</p>
              
            </div>
        )
    }
}