import React from 'react';

import Background from './../../assets/footer-bg.jpg';

interface State {

}

interface Props {

}


var footerStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover'
}

export default class Footer extends React.Component<Props, State> {
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){

        return(
            <div className="footer" style={footerStyle} >
             <h3>Kontakt</h3>
            
            <p className="title">
            Hempamed by Solidmind Group GmbH
            </p>

            <p>
            Wenn du Fragen oder Wünsche hast, erreichst du uns unter:
            </p>
         
            <p>
            E-Mail: service @ hempamed.de
            </p>


            <p>
            Telefon: +49 (0) 89 380 368 42
            </p>

        

            </div>
        )
    }
}