import React from 'react';
import './Header.scss';
import Logo from './../../assets/logo.png';
import Background from './../../assets/background.jpg';

interface State {

}

interface Props {



}

var headerStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover'
}

export default class Header extends React.Component<Props, State> {
    constructor(props){
        super(props);
        this.state = {}
    }





    render(){

        return(
            <div className="header" style={headerStyle}>

                <img className="logo_img" src={Logo}></img>
               
                <h2 className="claim">
                Natürliche Nahrungsergänzung aus Deutschland
                </h2>


                <p>
                Zu welchen unserer Online-Shops dürfen wir Sie weiterleiten? 
                </p>

                <p>
                Bitte klicken Sie auf eine der Flaggen.
                </p>


                <div className="flag_div">
                <a href="http://go.hempamed.net/ ">
                   <div className="flag_div_left">
                  <img src="https://v.fastcdn.co/u/7e08872e/31552971-0-if-Germany-flat-9209.png"></img> 
                   <a className="country_button">Deutschland</a>
                   </div>
                   </a>
                   <a href="http://go.hempamed.net/ ">
                    <div className="flag_div_right">
                    <img src="https://v.fastcdn.co/u/7e08872e/31552966-0-if-Austria-flat-9196.png"></img> 
                    <a className="country_button">Österreich</a>
                    </div>
                    </a>
                </div>
            </div>
        )
    }
}