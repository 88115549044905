import React from 'react';
import Benefit from './Benefit';
import './Benefits.scss';

import preisLeistung from './../../assets/preis.svg';
import Service from './../../assets/service.svg';
import Garantie from './../../assets/garantie.svg';
import Versand from './../../assets/versand.svg';

interface State {

}

interface Props {

}

export default class Benefits extends React.Component<Props, State> {
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){

        return(
            <div className="benefits">
                <Benefit icon={preisLeistung} headline="bestes Preis Leistungs Verhältnis"></Benefit>
                <Benefit icon={Service}  headline="exzellenter Kundenservice" ></Benefit>
                <Benefit icon={Garantie} headline="30-Tage-Geld-zurück-Garantie" ></Benefit>
                <Benefit icon={Versand} headline="Versand in 1-3 Tagen" ></Benefit>

            </div>
        )
    }
}